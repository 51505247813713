@import "../../styles/mixin";

.header {
  @include scale(height, 70px);
  width: 100%;
  border-bottom: solid rgb(230, 230, 230) 1px;
  position: fixed;
  background: #ffffff;
  z-index: 9999;

  .logo {
    width: 100%;
    height: 100%;
    background-repeat: no-repeat !important;
    @include scale(background-position, 20px center !important);
    @include scale(background-size, auto 70px !important);
  }
}
