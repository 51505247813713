@import "../../styles/mixin";

.sceneItem {
  width: 50%;
  @include scale(height, 250px);
  @include scale(margin-bottom, 20px);
  display: flex;
  justify-content: center;
  align-items: center;

  .inner {
    height: 100%;
    background: rgb(244, 244, 244);
    @include scale(border-radius, 20px);
    display: block;
    width: 100%;

    .header {
      @include scale(height, 160px);

      .thumbnail {
        height: 100%;
        @include scale(border-radius, 4px);
        background-size: 120% !important;
        background-repeat: no-repeat !important;
        background-position: center !important;
      }
    }

    .information {
      @include scale(padding, 0 15px);
      @include scale(height, 90px);
      position: relative;

      .name {
        @include scale(font-size, 12px);
        @include scale(line-height, 14px);
        @include scale(margin-bottom, 6px);
        @include scale(height, 28px);
        overflow: hidden;
        text-overflow: ellipsis;
        color: rgb(128, 128, 128);
        white-space: pre-wrap;
        display: flex;
      }

      .brand {
        @include scale(font-size, 15px);
        @include scale(line-height, 18px);
        @include scale(margin-bottom, 6px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .price {
        @include scale(font-size, 15px);
        @include scale(line-height, 13px);
        @include scale(bottom, 15px);
        font-weight: bold;
        position: absolute;
      }
    }
  }

  &.odd {
    @include scale(padding-left, 10px);
  }

  &:not(.odd) {
    @include scale(padding-right, 10px);
  }
}
