@import "../../styles/mixin";

.categoryItem {
  @include scale(padding, 0 16px);
  @include scale(font-size, 14px);
  color: rgb(204, 204, 204);
  transition: color ease-in-out .15s;
  position: relative;
  white-space: nowrap;

  &.isSelected {
    color: #000;
    font-weight: bold;
    border-bottom: solid #000 1px;
  }

  .inner {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}
