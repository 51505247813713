@import "../../styles/mixin";

.collection {
  display: flex;
  flex-direction: column;

  .header {
    @include scale(top, 70px);
    display: flex;
    flex-direction: column;
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: #ffffff;
  }

  .sceneItems {
    display: flex;
    flex-wrap: wrap;
    @include scale(padding, 130px 20px 0 20px);
  }

  &.hasCategory {
    .sceneItems {
      @include scale(padding-top, 170px);
    }
  }
}
