@import "../../styles/mixin";

.categories {
  @include scale(height, 40px);
  display: flex;

  .inner {
    display: flex;
    height: 100%;
    border-bottom: solid rgb(230, 230, 230) 1px;
  }
}
