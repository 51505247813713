@import "../../styles/mixin";

.dimmer {
  background: rgba(#000, .7);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}

.sceneDetail {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  background: #ffffff;
  @include scale(border-top-left-radius, 10px);
  @include scale(border-top-right-radius, 10px);

  .inner {
    @include scale(padding, 0 20px);
    @include scale(margin-top, 32px);

    .categories {
      display: flex;
      align-items: center;
      @include scale(font-size, 14px);
      @include scale(line-height, 17px);
      @include scale(margin-bottom, 27px);
      color: rgb(204, 204, 204);
      align-content: center;

      a, svg {
        display: flex;
      }

      svg {
        @include scale(margin, 0 2px);
        @include scale(font-size, 16px);
        appearance: none;
        vertical-align: middle;
        top: -1px;
        position: relative;
      }

      .category {
        color: #000;
        font-weight: bold;
      }
    }

    .viewer {
      border: none;
      width: 100%;
      @include scale(height, 285px);
      background: rgb(244, 244, 244);
      @include scale(border-radius, 20px);
      @include scale(margin-bottom, 24px);
    }

    .information {
      display: flex;
      flex-direction: column;

      .brand {
        @include scale(font-size, 20px);
        @include scale(line-height, 24px);
        @include scale(margin-bottom, 4px);
        font-weight: bold;
      }

      .name {
        @include scale(font-size, 18px);
        @include scale(line-height, 23px);
        @include scale(margin-bottom, 5.4px);
        font-weight: normal;
        color: rgb(153, 153, 153);
      }

      .price {
        @include scale(font-size, 24px);
        @include scale(line-height, 30px);
        @include scale(margin-bottom, 12px);
        font-weight: bold;
      }

      .description {
        @include scale(font-size, 14px);
        @include scale(line-height, 24px);
        @include scale(height, 82px);
        overflow: auto;
        color: rgb(128, 128, 128);
      }
    }
  }

  .bar {
    position: absolute;
    left: 0;
    right: 0;
    width: 50%;
    @include scale(height, 50px);
    margin: auto;

    &::after {
      content: '';
      @include scale(width, 100px);
      @include scale(height, 3px);
      background: #000000;
      position: absolute;
      left: 0;
      right: 0;
      @include scale(top, 8.5px);
      margin: auto;
      @include scale(border-radius, 10px);
    }
  }

  .actions {
    position: absolute;
    bottom: 0;
    width: 100%;
    @include scale(height, 44px);
    background: rgb(244, 244, 244);
    display: flex;

    .buttons {
      display: flex;
      width: 100%;

      .button {
        display: flex;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 50%;
        border: none;
        background: none;
        align-content: center;
        justify-content: center;
        @include scale(font-size, 16px);
        @include scale(line-height, 14px);
        font-weight: bold;
        position: relative;
        &:first-child {
          &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            height: 60%;
            width: 1px;
            background: #ccc;
          }
        }
      }
    }
  }
}
